import {
    FunctionComponent,
    createElement,
    useState,
    useEffect,
    useCallback,
    ChangeEvent,
} from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

type Props = DialogProps & {
    close: (secretPhrase: any) => void
}

export const ImportForm: FunctionComponent<Props> = ({
    close,
    ...dialogProps
}) => {
    const classes = useStyles()
    const [formComplete, setFormComplete] = useState(false)
    const [secretPhrase, setSecretPhrase] = useState('')

    useEffect(() => {
        setFormComplete(secretPhrase.length != 0)
    }, [secretPhrase])

    useEffect(() => {
        setSecretPhrase('')
    }, [])

    const submit = (event: any) => {
        if (formComplete) {
            close(secretPhrase)
            setSecretPhrase('')
        }
    }

    const handleChange = (event: any) => {
        setSecretPhrase(event.target.value)
    }

    const cancel = (event: any) => {
        setSecretPhrase('')
        close(null)
    }

    return (
        <Dialog {...dialogProps}>
            <DialogTitle>Import account</DialogTitle>

            <DialogContent className={classes.main}>
                <form>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <TextField
                                InputProps={{ style: { fontSize: 14 } }}
                                label="secret phrase"
                                type="text"
                                name="secret_phrase"
                                value={secretPhrase}
                                onChange={handleChange}
                                autoFocus
                                margin="dense"
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    className={classes.btn}
                    variant="contained"
                    onClick={submit}
                    disabled={!formComplete}
                >
                    Import
                </Button>
                <Button onClick={cancel} className={classes.btn}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            width: '100px',
        },
        main: {
            width: '600px',
        },
    })
)
