import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { countries as COUNTRIES } from "countries-list"
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { currencies } from "../../etc/currencies"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { rpc } from "../../services/node"
import Input from "../layout/form/input"
import Select from "../layout/form/select"
import { useModal } from "../../hooks/use-modal"
import { GatewayVerification } from "../../components/settings/gateway-verification"
import { lightTheme, darkTheme } from "../../services/theme"
import { useStyle } from "../../hooks/use-style"
import { DonationAddress } from "../../types"
import { useMedia } from "../../hooks/use-media"

type Props = {
  close: (address: any) => void
}

export const DonationAddressForm: FunctionComponent<Props> = ({ close }) => {
  const [formComplete, setFormComplete] = useState(false)
  const { user, setBalance } = useUser()
  const { numColumns } = useMedia()
  const [thirdPartyGateway, setThirdPartyGateway] = useState(
    "gateway.smartlike.org"
  )
  const [publicGateway, setPublicGateway] = useState("")
  const { setContent } = useModal()
  const [notice, setNotice] = useState("")

  const [address, setAddress] = useState<DonationAddress>({
    address: "",
    processor: "PayPal",
    min_amount: 0,
    max_amount: 1000,
    currency: "",
    country: "",
    description: "",
    gateway: "gateway.smartlike.org",
    kind: "third_party",
    timestamp: 0,
  })

  const processors = ["PayPal"]

  const css = useStyle(theme => ({
    buttonBackgroundColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    inactiveButtonBackgroundColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    buttonTextColor: "black",
    inactiveButtonTextColor: "gray",
  }))

  useEffect(() => {
    setFormComplete(
      address.address.length != 0 &&
        address.currency.length != 0 &&
        address.country.length != 0 &&
        (address.kind === "self" || address.gateway.length != 0)
    )
  }, [address])

  const verifyServer = useCallback(() => {
    setNotice("")
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(address.address).toLowerCase())) {
    } else {
      setNotice("Address is not valid.")
      return
    }

    if (
      address.gateway.length != 0 &&
      (address.kind == "third_party" || address.kind == "public")
    ) {
      rpc(
        "get_gateway_info",
        address.gateway,
        user,
        getCachedSecret(),
        async response => {
          console.log(response)
          if (response.status == "ok") {
            if (
              address.kind == "public" &&
              response.data.channel.id != user.id
            ) {
              setNotice("The gateway is registered under another account.")
              return
            }

            setContent(
              <GatewayVerification
                address={address}
                gatewayInfo={response.data}
                close={close}
              />
            )
          } else {
            if (address.kind == "public") {
              // new server?
              //setNotice("Pinging the gateway...")

              const response = await Promise.resolve(
                fetch(
                  "https://" +
                    address.gateway +
                    "/ping?token=" +
                    Math.floor(Math.random() * 100000000)
                )
                  .then(res => res.json())
                  .catch(error => {
                    console.log("json parsing error occured ", error)
                    return null
                  })
              )
              console.log(response)
              if (response && "token" in response) {
                setNotice("Ping successful.")
                setContent(
                  <GatewayVerification
                    address={address}
                    gatewayInfo={null}
                    close={close}
                  />
                )
              } else
                setNotice(
                  "Is the gateway online? It failed to reply to a ping over HTTPS."
                )
            } else {
              setNotice(
                "The gateway is not registered. Please check the spelling."
              )
            }
          }
        }
      )
    } else {
      setContent(
        <GatewayVerification
          address={address}
          gatewayInfo={null}
          close={close}
        />
      )
    }
  }, [user, address])

  const countries = useMemo(() => {
    let c = Object.entries(COUNTRIES)
      .map(([k, v]) => ({
        code: k,
        name: v.name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    c.push({ code: "", name: "" })
    return c
  }, [])

  const onAddressChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAddress({
        ...address,
        address: event.currentTarget.value,
      })
    },
    [address]
  )

  const onProcessorChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setAddress({
        ...address,
        processor: event.currentTarget.value,
      })
    },
    [address]
  )

  const onCurrencyChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setAddress({
        ...address,
        currency: event.currentTarget.value,
      })
    },
    [address]
  )

  const onCountryChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setAddress({
        ...address,
        country: event.currentTarget.value,
      })
    },
    [address]
  )

  const onDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAddress({
        ...address,
        description: event.currentTarget.value,
      })
    },
    [address]
  )

  const onGatewayTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAddress({
        ...address,
        kind: event.currentTarget.value,
      })
    },
    [address]
  )

  const onGatewayServerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (address.kind == "third_party")
        setThirdPartyGateway(event.currentTarget.value)
      else setPublicGateway(event.currentTarget.value)

      setAddress({
        ...address,
        gateway: event.currentTarget.value,
      })
    },
    [address]
  )

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            close(null)
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <h1>Add donation address</h1>
      {numColumns == 1 ? (
        <>
          <div className="parameter">
            <div className="parName">Address</div>
            <Input value={address.address} onChange={onAddressChange} />
          </div>
          <div className="parameter">
            <div className="parName">Processor</div>
            <Select value={address.processor} onChange={onProcessorChange}>
              {processors.map(c => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Select>
          </div>
          <div className="parameter">
            <div className="parName">Currency</div>
            <Select value={address.currency} onChange={onCurrencyChange}>
              <option key={""} value={""}>
                {""}
              </option>
              {currencies.map(c => (
                <option key={c.c} value={c.c}>
                  {c.c} - {c.n}
                </option>
              ))}
            </Select>
          </div>

          <div className="parameter">
            <div className="parName">Country</div>
            <Select value={address.country} onChange={onCountryChange}>
              {countries.map(c => (
                <option key={c.code} value={c.code}>
                  {c.name}
                </option>
              ))}
            </Select>
          </div>

          <div className="parameter">
            <div className="parName">Description</div>
            <Input value={address.description} onChange={onDescriptionChange} />
          </div>

          <div className="parameter">
            <div className="parName">Confirmation processing</div>

            <div style={{ marginTop: "10px" }}>
              <input
                type="radio"
                id="third_party"
                name="confirmations"
                value="third_party"
                style={{
                  marginRight: "10px",
                  verticalAlign: "middle",
                }}
                checked={address.kind === "third_party"}
                onChange={onGatewayTypeChange}
              />
              <label for="third_party">
                trusted - trust a <a href="#">gateway</a> hosted by a
                third-party to register your donation confirmations in
                Smartlike:
              </label>
            </div>
            <div style={{}}>
              <Input
                value={thirdPartyGateway}
                onChange={onGatewayServerChange}
                disabled={address.kind !== "third_party"}
                style={{}}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <input
                type="radio"
                id="public"
                name="confirmations"
                value="public"
                style={{
                  marginRight: "10px",
                  verticalAlign: "middle",
                }}
                checked={address.kind === "public"}
                onChange={onGatewayTypeChange}
              />
              <label for="public">
                public - host the <a href="#">gateway</a> to guarantee the best
                privacy for your donors and help the community to register their
                donation confirmations in Smartlike:
              </label>
            </div>
            <div style={{}}>
              <Input
                value={publicGateway}
                onChange={onGatewayServerChange}
                disabled={address.kind !== "public"}
                style={{}}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <input
                type="radio"
                id="private"
                name="confirmations"
                value="private"
                style={{
                  marginRight: "10px",
                  verticalAlign: "middle",
                }}
                checked={address.kind === "private"}
                onChange={onGatewayTypeChange}
              />
              <label for="private">
                private - host the <a href="#">gateway</a> to guarantee the best
                privacy for your donors
              </label>
            </div>
          </div>
        </>
      ) : (
        <div>
          <table style={{ borderSpacing: "10px 10px" }}>
            <tbody>
              <tr>
                <td>
                  <h3>Address</h3>
                </td>
                <td>
                  <Input value={address.address} onChange={onAddressChange} />
                </td>

                <td>
                  <h3>Processor</h3>
                </td>
                <td>
                  <Select
                    value={address.processor}
                    onChange={onProcessorChange}
                  >
                    {processors.map(c => (
                      <option key={c} value={c}>
                        {c}
                      </option>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>Currency</h3>
                </td>
                <td>
                  <Select value={address.currency} onChange={onCurrencyChange}>
                    <option key={""} value={""}>
                      {""}
                    </option>
                    {currencies.map(c => (
                      <option key={c.c} value={c.c}>
                        {c.c} - {c.n}
                      </option>
                    ))}
                  </Select>
                </td>
                <td>
                  <h3>Country</h3>
                </td>
                <td>
                  <Select value={address.country} onChange={onCountryChange}>
                    {countries.map(c => (
                      <option key={c.code} value={c.code}>
                        {c.name}
                      </option>
                    ))}
                  </Select>
                </td>
              </tr>

              <tr>
                <td>
                  <h3>Description</h3>
                </td>
                <td colSpan={3}>
                  <Input
                    value={address.description}
                    onChange={onDescriptionChange}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <h3>Processing</h3>
                </td>
                <td colSpan={3} style={{ textAlign: "left" }}>
                  <div style={{}}>
                    <div style={{ width: "100px", display: "inline-table" }}>
                      <input
                        type="radio"
                        id="third_party"
                        name="confirmations"
                        value="third_party"
                        style={{ marginRight: "10px", verticalAlign: "middle" }}
                        checked={address.kind === "third_party"}
                        onChange={onGatewayTypeChange}
                      />
                      <label for="third_party">trusted:</label>
                    </div>
                    <div style={{ float: "right" }}>
                      <Input
                        value={thirdPartyGateway}
                        onChange={onGatewayServerChange}
                        disabled={address.kind !== "third_party"}
                        style={{ float: "right", width: "350px" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      marginLeft: "33px",
                    }}
                  >
                    trust a <a href="#">gateway</a> hosted by a third-party to
                    register your donation confirmations in Smartlike
                  </div>
                </td>
              </tr>

              <tr>
                <td></td>
                <td colSpan={3} style={{ textAlign: "left" }}>
                  <div style={{}}>
                    <div style={{ width: "100px", display: "inline-table" }}>
                      <input
                        type="radio"
                        id="public"
                        name="confirmations"
                        value="public"
                        style={{ marginRight: "10px", verticalAlign: "middle" }}
                        checked={address.kind === "public"}
                        onChange={onGatewayTypeChange}
                      />
                      <label for="public">public:</label>
                    </div>
                    <div style={{ float: "right" }}>
                      <Input
                        value={publicGateway}
                        onChange={onGatewayServerChange}
                        disabled={address.kind !== "public"}
                        style={{ float: "right", width: "350px" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      marginLeft: "33px",
                    }}
                  >
                    host the <a href="#">gateway</a> to guarantee the best
                    privacy for your donors and help the community to register
                    their donation confirmations in Smartlike
                  </div>
                </td>
              </tr>

              <tr>
                <td></td>
                <td colSpan={3} style={{ textAlign: "left" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100px", display: "inline-table" }}>
                      <input
                        type="radio"
                        id="private"
                        name="confirmations"
                        value="private"
                        style={{ marginRight: "10px", verticalAlign: "middle" }}
                        checked={address.kind === "private"}
                        onChange={onGatewayTypeChange}
                      />
                      <label for="private">private</label>
                    </div>
                    <div>
                      <div style={{ float: "right", width: "350px" }}></div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      marginLeft: "33px",
                    }}
                  >
                    host the <a href="#">gateway</a> to guarantee the best
                    privacy for your donors
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div style={{ marginBottom: "15px", marginTop: "15px", height: "10px" }}>
        <div style={{ display: "initial", color: "red" }}>{notice}</div>
        <div style={{ float: "right" }}>
          <button
            disabled={!formComplete}
            onClick={() => {
              verifyServer()
            }}
          >
            Submit
          </button>
        </div>
      </div>

      <style jsx>{`
        .parameter {
          margin-top: 10px;
        }
        .close:hover {
          cursor: pointer;
        }

        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h1 {
          font-weight: 500;
        }
        h2 {
          font-size: inherit;
          font-weight: 500;
        }
        h3 {
          font-size: inherit;
          font-weight: 500;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
        footer {
          text-align: right;
          margin-bottom: 25px;
        }
        button {
          border: none;
          border-radius: 0.3em;
          background-color: ${css.buttonBackgroundColor};
          transition: 200ms background-color, 200ms color;
          padding: 0.5em 1em;
          color: black;
          min-width: 7.5em;
        }
        button:disabled {
          color: ${css.inactiveButtonTextColor};
        }
      `}</style>
    </section>
  )
}
