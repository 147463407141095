import React, { FunctionComponent } from "react"
import { Helmet as Head } from "react-helmet"
import ThreeColumnLayout from "../components/layout/three-column-layout"
import Preferences from "../components/preferences"

const PreferencesPage: FunctionComponent = () => {
  return (
    <>
      <Head>
        <title>Smartlike settings</title>
      </Head>

      <ThreeColumnLayout>
        {{
          content: <Preferences />,
          sidebar: null,
        }}
      </ThreeColumnLayout>

      <style jsx>{``}</style>
    </>
  )
}

export default PreferencesPage
