import { FunctionComponent, useEffect, useRef } from "react"
import React from "react"
import { TextField, TextFieldProps } from "@material-ui/core"

const SettingsTextField: FunctionComponent<TextFieldProps> = props => {
  const ref = useRef<HTMLInputElement>(null)

  /*useEffect(() => {
    ref.current?.focus()
    console.log("focus ")
  }, [props.value])*/

  useEffect(() => {
    ref.current?.focus()
  }, [])

  return <TextField ref={ref} {...props} />
}

export default SettingsTextField
