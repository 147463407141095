import { faInfoCircle, faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useState } from "react"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { rpc } from "../../services/node"
import { showToast } from "../status-notification"
import { useModal } from "../../hooks/use-modal"
import { lightTheme, darkTheme } from "../../services/theme"
import { useStyle } from "../../hooks/use-style"
import { DonationAddress, GatewayInfo } from "../../types"
import { DonationAddressForm } from "../../components/settings/donation-address-form"
import Avatar from "../layout/avatar"
import { navigate } from "gatsby"

type Props = {
  address: DonationAddress
  close: (address: any) => void
  gatewayInfo: GatewayInfo | null
}

export const GatewayVerification: FunctionComponent<Props> = ({
  address,
  close,
  gatewayInfo,
}) => {
  const { user, updateBalance } = useUser()
  const { setContent } = useModal()
  const [state, setState] = useState("initial")

  const css = useStyle(theme => ({
    buttonBackgroundColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    inactiveButtonBackgroundColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    buttonTextColor: "black",
    inactiveButtonTextColor: "gray",
  }))

  const verifyServer = useCallback(() => {
    //console.log("verifyServer")
    setContent(<DonationAddressForm close={close} />)
    //addAddress()
  }, [user, address])

  const addAddress = useCallback(() => {
    address.timestamp = Math.round(new Date().getTime() / 1000)
    if (address.kind === "self") address.gateway = ""

    const msg = JSON.stringify(address)
    console.log(msg)

    rpc("add_donation_address", msg, user, getCachedSecret(), function (res) {
      console.log(res)
      let message
      if (res.status == "ok" /* && res.data && res.data.balance*/) {
        message = "Donation address added"
        //if (user) updateBalance(-0.01 * user.displayed_currency_rate)
      } else {
        message = "Failed: " + res.data
      }
      showToast(faInfoCircle, "Smartlike network", message)
      close(address)
    })
  }, [user, address])

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            close(null)
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <div>
        {address.kind == "third_party" && gatewayInfo != null ? (
          <>
            <h1>Third-party gateway information</h1>
            <div>The gateway was registered by:</div>
            <div
              style={{
                width: "100%",
                marginBottom: "15px",
                display: "flex",
                marginTop: "15px",
              }}
            >
              <div style={{ marginRight: "5px" }}>
                <Avatar
                  image={gatewayInfo.channel.image ?? undefined}
                  label={gatewayInfo.channel.title}
                  size={70}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "inline-table",
                  fontFamily: "Roboto",
                  paddingTop: "5px",
                }}
              >
                <div className="selector">
                  <div className="flex">
                    <strong
                      onClick={() => {
                        navigate("/channel/" + gatewayInfo.channel.id)
                      }}
                      style={{ fontSize: "14px" }}
                    >
                      {gatewayInfo.channel.name.length != 0 ? (
                        <>
                          <span>{gatewayInfo.channel.title}</span>
                          <span className="handle">
                            @{gatewayInfo.channel.name}
                          </span>
                        </>
                      ) : (
                        <>{gatewayInfo.channel.title}</>
                      )}
                    </strong>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "8px",
                    paddingLeft: "5px",
                    fontSize: "12px",
                  }}
                >
                  {gatewayInfo.channel.description}
                </div>
                {/*
          <div style={{ paddingTop: "7px" }}>
            {gatewayInfo.channel.aliases.map(a => (
              <strong
                onClick={() => {
                    navigate("/channel/" + a.id)
                }}
              >
                {a.alias}
              </strong>
            ))}
            </div>*/}
              </div>
            </div>
            <div>
              They commited to use the <a href="">open source software</a> that
              doesn't store your donor's private data.
            </div>
          </>
        ) : address.kind == "public" ? (
          <>
            <h1>Hosting your own gateway</h1>
            <div>
              Thank you for letting the community use your gateway!
              <br />
              They'll be informed that you use{" "}
              <a href="">open source software</a> that doesn't store donor's
              private data and do your best to maintain the installation secure
              and updated.
            </div>
          </>
        ) : (
          <>
            <h1>Using private ...</h1>
          </>
        )}

        <footer>
          <button
            onClick={() => {
              addAddress()
            }}
          >
            Confirm
          </button>
          <button
            onClick={() => {
              close(null)
            }}
            style={{ marginLeft: "5px" }}
          >
            Cancel
          </button>
        </footer>
      </div>

      <style jsx>{`
        .close:hover {
          cursor: pointer;
        }

        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h1 {
          font-weight: 500;
        }
        h2 {
          font-size: inherit;
          font-weight: 500;
        }
        h3 {
          font-size: inherit;
          font-weight: 500;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
        footer {
          text-align: right;
          margin-bottom: 5px;
          margin-top: 20px;
        }
        button {
          border: none;
          border-radius: 0.3em;
          background-color: ${css.buttonBackgroundColor};
          transition: 200ms background-color, 200ms color;
          padding: 0.5em 1em;
          color: black;
          min-width: 7.5em;
        }
        button:disabled {
          color: ${css.inactiveButtonTextColor};
        }
      `}</style>
    </section>
  )
}
