import {
  faAd,
  faAngry,
  faCoins,
  faExclamationTriangle,
  faFlag,
  faGlobe,
  faHandPaper,
  faPrayingHands,
  faSun,
  faSwords,
  faThumbsUp,
  faTrashAlt,
  faVenusMars,
  faUsdSquare,
  faUnlink,
  faDoNotEnter,
  faUserSecret,
  faEye,
  faCopyright,
  faThumbsDown,
  faImage,
  faHandMiddleFinger,
  faUser,
} from "@fortawesome/pro-light-svg-icons"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { navigate } from "gatsby"
import { countries as COUNTRIES, languages as LANGUAGES } from "countries-list"
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import InfiniteScroll from "react-infinite-scroll-component"
import { SingleSelect, TagsSelect } from "react-select-material-ui"
import Switch from "react-switch"
import { DonationAddressForm } from "../components/settings/donation-address-form"
import { ImportForm } from "../components/settings/import-form"
import { showToast } from "../components/status-notification"
import { currencies, getCurrencySymbol } from "../etc/currencies"
import { useFilters } from "../hooks/use-filters"
import { useStyle } from "../hooks/use-style"
import { useTheme as useSiteTheme } from "../hooks/use-theme"
import { getCachedSecret, useUser } from "../hooks/use-user"
import { rpc } from "../services/node"
import { darkTheme, lightTheme } from "../services/theme"
import { useModal } from "../hooks/use-modal"
import Notice from "../components/widgets/notice"

import { withStyles } from "@material-ui/core/styles"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import SettingsTextField from "../components/settings-text-field"
import IdentityEditor from "../components/identity-editor"
import Input from "../components/layout/form/input"
//import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field, useFormik } from "formik"

const Preferences: FunctionComponent = () => {
  const { filters, updateFilters } = useFilters()
  const { theme, toggleTheme } = useSiteTheme()
  const { setContent } = useModal()
  const [newModerator, setNewModerator] = useState("")

  const languages = useMemo(() => {
    let l = Object.entries(LANGUAGES)
      .map(([k, v]) => ({
        label: v.name,
        value: k,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))

    l.push({ label: "", value: "" })
    return l
  }, [])
  const defaultLanguages = useMemo(() => {
    let d = languages
      .filter(language => filters.languages?.includes(language.value))
      .map(({ value }) => value)
    //d = languages.map(({ value }) => value)
    console.log(d)
    return d
  }, [filters.languages])

  const onLanguagesChange = useCallback(
    (v: typeof languages) => {
      console.log(v)
      //updateFilters({ languages: v.map(({ value }) => value) })
      updateFilters({ languages: v })
    },
    [updateFilters]
  )

  const countries = useMemo(() => {
    let c = Object.entries(COUNTRIES)
      .map(([k, v]) => ({
        label: v.name,
        value: k,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    //c.push({name:"", code:"", capital:"", continent:"", currency:"", languages:[], phone:"", emoji:"", emojiU:"", native:""})
    c.push({ label: "", value: "" })
    return c
  }, [])
  const defaultCountries = useMemo(
    () =>
      countries
        .filter(country => filters.countries?.includes(country.value))
        .map(({ value }) => value),
    [filters.countries]
  )
  const onCountriesChange = useCallback(
    (v: typeof countries) => {
      updateFilters({ countries: v })
    },
    [updateFilters]
  )

  const toggleBitmaskFilter = useCallback(
    (bit: number) => {
      let flag = 1 << bit
      let c = filters.bitmask & flag
      let b = 0
      if (c > 0) b = filters.bitmask & ~flag
      else b = filters.bitmask | flag
      //console.log(filters.bitmask + " " + c + " " + b + " " + flag + " " + ~flag)

      updateFilters({
        bitmask: b,
      })
    },
    [filters.bitmask]
  )

  const toggleModerator = useCallback(() => {
    if (filters) {
      updateFilters({
        moderated: !filters.moderated,
      })
    }
  }, [filters.moderated])

  const css = useStyle(theme => ({
    textPrimaryColor:
      theme === "light"
        ? lightTheme.color.text.primary
        : darkTheme.color.text.primary,
    avatarColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    avatarBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    switchColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    switchHandleColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    switchOutlineColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
  }))

  const Accordion = withStyles({
    root: {
      fontFamily: "Roboto",
      color: css.textPrimaryColor,
      backgroundColor: css.backgroundColor,
      border: "1px solid",
      borderColor: css.borderColor,
      transition: "200ms background-color, 200ms border-color",
      borderRadius: "0.3em",
      /*userSelect: "none",*/
    },
    expanded: {
      fontFamily: "Roboto",
      color: css.textPrimaryColor,
      backgroundColor: css.backgroundColor,
      border: "1px solid",
      borderColor: css.borderColor,
      transition: "200ms background-color, 200ms border-color",
      borderRadius: "0.3em",
      /*userSelect: "none",*/
    },
  })(MuiAccordion)

  const classes = useStyles()

  const [account, setAccount] = useState({
    id: "",
    publicKey: "",
    balance: {
      amount: 0,
      currency: "USD",
    },
    secret: "",
  })

  const [profile, setProfile] = useState({
    name: "",
    title: "",
    description: "",
    image: "",
    icon: "",
    cut: 10,
    url: "",
    country: "",
    language: "",
    aliases: [],
    timestamp: 0,
    thanks: "",
  })

  const [alias, setAlias] = useState({
    type: "youtube.com",
    id: "",
  })

  const [state, setState] = useState({
    items: [],
    isLoaded: false,
    hasMore: true,
    error: null,
  })

  const {
    user,
    setUser,
    setType,
    clearLocalUserData,
    updateBalance,
    updateSubscriptions,
    syncProfile,
  } = useUser()

  const deleteDonationAddress = useCallback(
    (event: any) => {
      var e = event.target.closest("button")
      //console.log(e.getAttribute('data-address') + " " + e.getAttribute('data-processor'))
      const msg = {
        address: e.getAttribute("data-address"),
        processor: e.getAttribute("data-processor"),
      }
      rpc(
        "delete_donation_address",
        JSON.stringify(msg),
        user,
        getCachedSecret(),
        res => {
          console.log(res)
          let message
          if (res.status == "ok") {
            message = "Donation address deleted"
            setState({
              ...state,
              items: state.items.filter(item => item.address !== msg.address),
            })
          } else {
            message = "Failed: " + res.data
          }
          showToast(faInfoCircle, "Smartlike network", message)
          /*
        setState({
            isLoaded: false,
            hasMore: true,
            items: [],
            error: null,
        })*/
        }
      )
    },
    [user]
  )

  const loadCurrencies = () => {
    let c = []
    for (var i = 0; i < currencies.length; i++) {
      c.push({
        label: currencies[i].c + " - " + currencies[i].n,
        value: currencies[i].c,
      })
    }
    return c
  }

  const displayedCurrencies = loadCurrencies()
  const account_types = ["unset", "local", "global"]

  const [isImportDialogOpen, setImportDialogOpen] = useState(false)
  const openImportDialog = useCallback(() => setImportDialogOpen(true), [])
  const closeImportDialog = useCallback((secret: string) => {
    setImportDialogOpen(false)
    if (secret != null && !secret.target) {
      chrome.runtime.sendMessage(
        "ghpgncoijpkgbjmjfoohhcgplfecfimf",
        { type: "import", secret: secret },
        response => {
          if (response != null) {
            setProfile(response)
          }
        }
      )
    }
  }, [])

  const [selectedAccounts, setSelectedAccounts] = useState([])

  const [donationAddressesExpanded, setDonationAddressesExpanded] = useState(
    false
  )
  const loadDonationAddresses = (event: object, expanded: boolean) => {
    setDonationAddressesExpanded(expanded)
  }
  useEffect(() => {
    if (donationAddressesExpanded && state.items.length == 0) fetchMoreData()
  }, [donationAddressesExpanded])

  const fetchMoreData = useCallback(() => {
    if (donationAddressesExpanded == false) {
      setState({
        items: [],
        hasMore: false,
        isLoaded: true,
        error: null,
      })
      return
    }

    rpc(
      "get_donation_addresses",
      user?.id,
      user,
      getCachedSecret(),
      response => {
        console.log(response)
        if (response.status == "ok") {
          setState({
            isLoaded: true,
            hasMore: response.data.length == 40,
            items: state.items.concat(response.data),
            error: null,
          })
        } else {
          setState({
            items: [],
            hasMore: false,
            isLoaded: true,
            error: response.data,
          })
        }
      }
    )
  }, [user, donationAddressesExpanded])

  const addNewAddress = (account: any) => {
    setContent(null)
  }

  const openDialog = useCallback(() => {
    setContent(<DonationAddressForm close={addNewAddress} />)
  }, [])

  const [displayedCurrency, setDisplayedCurrency] = useState("USD")

  useEffect(() => {
    if (user) {
      setDisplayedCurrency(user.displayed_currency)
      //setModerationFeed(user.show_moderation_feed)
    }
  }, [user])

  const changeDisplayedCurrency = useCallback(
    (currency: string) => {
      console.log(currency)
      if (user) {
        setUser(
          user =>
            user && {
              ...user,
              displayed_currency: currency,
              displayed_currency_symbol: getCurrencySymbol(currency),
            }
        )
        updateSubscriptions(user.subscriptions)
      }
    },
    [user, setUser]
  )

  const [likeValue, setLikeValue] = useState(0.01)
  useEffect(() => {
    if (user) {
      setLikeValue(user.like_value)
    }
  }, [user])

  const changeLikeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.value.length == 0) {
        setLikeValue(0)
      } else {
        let v = parseFloat(event.currentTarget.value)
        //if (v > 100) v = 100
        if (v < 0) v = 0
        setLikeValue(v)

        if (user) {
          setUser(
            user =>
              user && {
                ...user,
                like_value: parseFloat(event.target.value),
              }
          )
        }
      }
    },
    [user]
  )

  const [profileExpanded, setProfileExpanded] = useState(false)
  const loadProfile = (event: object, expanded: boolean) => {
    setProfileExpanded(expanded)
  }

  useEffect(() => {
    console.log("settings useEffect " + profileExpanded + " " + user)
    if (profileExpanded && !getCachedSecret()) {
      syncProfile()
      //navigate("/login", { state: { modal: true } })
    }
  }, [profileExpanded])

  const identityRef = useRef<HTMLElement>(null)

  const formik = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      email: "",
    },
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  return (
    <>
      <Head>
        <title>Smartlike settings</title>
      </Head>

      <div className="">
        <div className={classes.chapter}>Preferences</div>
        <div className="paper">
          <div className="dtable">
            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faGlobe} />
              </div>
              <div className="dcell">
                <TagsSelect
                  label="Show content in languages"
                  options={languages}
                  onChange={onLanguagesChange}
                  defaultValues={defaultLanguages}
                  SelectProps={{
                    isCreatable: false,
                    msgNoOptionsAvailable: "All selected",
                    msgNoOptionsMatchFilter: "Unknown language",
                  }}
                  style={{ zIndex: 3 }}
                />
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faFlag} />
              </div>
              <div className="dcell">
                <TagsSelect
                  label="Hide content from countries"
                  options={countries}
                  onChange={onCountriesChange}
                  defaultValues={defaultCountries}
                  SelectProps={{
                    isCreatable: false,
                    msgNoOptionsAvailable: "All selected",
                    msgNoOptionsMatchFilter: "Unknown country",
                  }}
                  style={{ zIndex: 2 }}
                />
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faCoins} />
              </div>
              <div className="dcell">
                <SingleSelect
                  value={displayedCurrency}
                  placeholder="My currency"
                  options={displayedCurrencies}
                  onChange={changeDisplayedCurrency}
                  style={{ width: 300 }}
                  helperText="displayed currency"
                />
              </div>
            </div>

            <div className="drow">
              <div className="badge"></div>
              <div
                className="dcell"
                style={{ fontSize: "12px", verticalAlign: "bottom" }}
              >
                Like value
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faThumbsUp} />
              </div>
              <div className="dcell">
                <div
                  style={{
                    display: "flex",
                    float: "left",
                    width: "120px",
                  }}
                >
                  <input
                    id="like_value"
                    type="number"
                    step="0.01"
                    value={likeValue}
                    onChange={changeLikeValue}
                    className="amount"
                  />
                  {user != null && (
                    <div style={{ margin: "auto", paddingLeft: "10px" }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: user.displayed_currency_symbol,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge"></div>
              <div
                className="dcell"
                style={{ fontSize: "12px", verticalAlign: "top" }}
              >
                Note the beta limitation: minimum amount is temporarily set to
                the equevalent of 0.01 USD
              </div>
            </div>
            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faSun} />
              </div>
              <div className="dcell">
                <div className="inline">
                  <label htmlFor="settings-preferences-theme">Dark theme</label>

                  <Switch
                    id="settings-preferences-theme"
                    checked={theme === "dark"}
                    onChange={toggleTheme}
                    offColor={css.switchColor}
                    onColor={css.switchColor}
                    activeBoxShadow={`0 0 0 1px ${css.switchOutlineColor}`}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {user?.account_type === "global" && (
          <>
            <div className={classes.chapter} style={{ marginTop: "30px" }}>
              Account
            </div>
            <Accordion onChange={loadProfile} expanded={profileExpanded}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Identity</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <IdentityEditor expanded={profileExpanded} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              onChange={loadDonationAddresses}
              expanded={donationAddressesExpanded}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Donation addresses
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <div className={classes.controls}>
                    <span className={classes.spacer} />
                    <Button
                      variant="contained"
                      className={classes.btn}
                      onClick={openDialog}
                    >
                      Add
                    </Button>
                  </div>
                  {state.items.map((e, index) => (
                    <div key={e.address} className="donation-address">
                      <div style={{}} className={classes.cell}>
                        {e.address}
                      </div>
                      <div style={{}} className={classes.cell}>
                        {e.processor}
                      </div>
                      <div style={{}} className={classes.cell}>
                        {e.country}
                      </div>
                      <div style={{}} className={classes.cell}>
                        <div style={{ float: "right" }}>{e.currency}</div>
                      </div>
                      <div style={{}}>
                        <IconButton
                          data-address={e.address}
                          data-processor={e.processor}
                          aria-label="Delete donation address"
                          onClick={deleteDonationAddress}
                          style={{ float: "right" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                  {/*
                        <div className={classes.tableHeader}>
                            <div style={{}}>account</div>
                            <div style={{}}>processor</div>
                            <div style={{}}>country</div>
                            <div style={{}}>
                            <div style={{ float: "right" }}>currency</div>
                            </div>
                            <div style={{}}></div>
                        </div>
                        <div className={classes.separator}></div>
                        <InfiniteScroll
                            dataLength={state.items.length}
                            next={fetchMoreData}
                            hasMore={state.hasMore}
                            className={classes.scroll}
                        >
                            {state.items.map((e, index) => (
                            <div key={e.address} className={classes.row}>
                                <div style={{}} className={classes.cell}>
                                {e.address}
                                </div>
                                <div style={{}} className={classes.cell}>
                                {e.processor}
                                </div>
                                <div style={{}} className={classes.cell}>
                                {e.country}
                                </div>
                                <div style={{}} className={classes.cell}>
                                <div style={{ float: "right" }}>
                                    {e.currency}
                                </div>
                                </div>
                                <div style={{}}>
                                <IconButton
                                    data-address={e.address}
                                    data-processor={e.processor}
                                    aria-label="Delete donation address"
                                    onClick={deleteDonationAddress}
                                    style={{ float: "right" }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                </div>
                            </div>
                            ))}
                        </InfiniteScroll>
                            */}
                </div>
              </AccordionDetails>
            </Accordion>

            <ImportForm
              open={isImportDialogOpen}
              onClose={closeImportDialog}
              close={closeImportDialog}
            />
          </>
        )}

        <div className={classes.chapter} style={{ marginTop: "30px" }}>
          Crowdsourced filters
        </div>
        <div
          className={
            "paper " + (filters && filters.moderated ? "" : "not_moderated")
          }
        >
          <div className="dtable">
            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faFlag} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-3">Gov't sponsored</label>

                  <Switch
                    id="filter-3"
                    checked={(filters.bitmask & (1 << 3)) == 0}
                    onChange={() => toggleBitmaskFilter(3)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faAd} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-2">Promotional</label>

                  <Switch
                    id="filter-2"
                    checked={(filters.bitmask & (1 << 2)) == 0}
                    onChange={() => toggleBitmaskFilter(2)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faHandPaper} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-7">Fake likes</label>

                  <Switch
                    id="filter-7"
                    checked={(filters.bitmask & (1 << 7)) == 0}
                    onChange={() => toggleBitmaskFilter(7)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-0">Fake</label>

                  <Switch
                    id="filter-0"
                    checked={(filters.bitmask & (1 << 0)) == 0}
                    onChange={() => toggleBitmaskFilter(0)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-5">Spam</label>

                  <Switch
                    id="filter-5"
                    checked={(filters.bitmask & (1 << 5)) == 0}
                    onChange={() => toggleBitmaskFilter(5)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow group-break"></div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faVenusMars} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-1">Adult content. I'm over 18.</label>

                  <Switch
                    id="filter-1"
                    checked={(filters.bitmask & (1 << 1)) == 0}
                    onChange={() => toggleBitmaskFilter(1)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faSwords} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-4">Violent</label>

                  <Switch
                    id="filter-4"
                    checked={(filters.bitmask & (1 << 4)) == 0}
                    onChange={() => toggleBitmaskFilter(4)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faPrayingHands} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-6">Religious offense</label>

                  <Switch
                    id="filter-6"
                    checked={(filters.bitmask & (1 << 6)) == 0}
                    onChange={() => toggleBitmaskFilter(6)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faAngry} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-8">Offensive</label>

                  <Switch
                    id="filter-8"
                    checked={(filters.bitmask & (1 << 8)) == 0}
                    onChange={() => toggleBitmaskFilter(8)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faHandMiddleFinger} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-15">Obscene language</label>

                  <Switch
                    id="filter-15"
                    checked={(filters.bitmask & (1 << 15)) == 0}
                    onChange={() => toggleBitmaskFilter(15)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow group-break"></div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faUser} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-16">Registration wall</label>

                  <Switch
                    id="filter-16"
                    checked={(filters.bitmask & (1 << 16)) == 0}
                    onChange={() => toggleBitmaskFilter(16)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faUsdSquare} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-12">Paywall</label>

                  <Switch
                    id="filter-12"
                    checked={(filters.bitmask & (1 << 12)) == 0}
                    onChange={() => toggleBitmaskFilter(12)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow group-break"></div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faUnlink} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-9">Broken link</label>

                  <Switch
                    id="filter-9"
                    checked={(filters.bitmask & (1 << 9)) == 0}
                    onChange={() => toggleBitmaskFilter(9)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faImage} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-14">Bad metadata</label>

                  <Switch
                    id="filter-14"
                    checked={(filters.bitmask & (1 << 14)) == 0}
                    onChange={() => toggleBitmaskFilter(14)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="group-break drow"></div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faCopyright} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-13">Copyright violation</label>

                  <Switch
                    id="filter-13"
                    checked={(filters.bitmask & (1 << 13)) == 0}
                    onChange={() => toggleBitmaskFilter(13)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              <div className="badge">
                <FontAwesomeIcon icon={faEye} />
              </div>

              <div className="dcell">
                <div className="inline">
                  <label htmlFor="filter-11">Security threat</label>

                  <Switch
                    id="filter-11"
                    checked={(filters.bitmask & (1 << 11)) == 0}
                    onChange={() => toggleBitmaskFilter(11)}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.chapter} style={{ marginTop: "30px" }}>
          Moderators
        </div>

        <div className="paper">
          <div className="dtable" style={{ marginBottom: "5px" }}>
            <div className="drow">
              <div className="dcell">
                <div className="inline">
                  <label htmlFor="moderator">
                    Basic abuse and vandalism crowd sourced moderation by
                    Smartlike.{" "}
                    <a
                      href="https://blog.smartlike.org/smartlike-moderation-policy"
                      target="_blank"
                    >
                      Policy.
                    </a>
                  </label>
                  <Switch
                    id="moderator"
                    checked={filters.moderated}
                    onChange={() => toggleModerator()}
                    activeBoxShadow={`0 0 0 0px ${css.switchOutlineColor}`}
                    onColor="#8cd98c"
                    offColor="#ff8080"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
            </div>

            <div className="drow">
              WARNING: Switching off moderation will show all content.
              <br />
              You need to be over 18.
            </div>
          </div>

          <Notice id="moderation_notice" submit="Got it">
            <p>
              More alternative independent moderators are coming soon!{" "}
              <a href="https://blog.smartlike.org/censorship-and-moderation">
                Read more.
              </a>
            </p>
          </Notice>
        </div>
        <div className="drow">
          <div className="dcell"></div>
          <div className="dcell">
            <button
              onClick={clearLocalUserData}
              className="submit"
              style={{ padding: "10px", marginLeft: "20px" }}
            >
              Clear local data
            </button>
          </div>
        </div>
      </div>

      <style jsx>{`

.donation-address {
width: 100%;
display: flex;
padding-top: 6px;
padding-bottom: 6px;
padding-left: 10px;
padding-right: 10px;
border-radius: 4px;
}

            h3 {
                font-size: inherit;
                font-weight: 500;
                margin: 0;
                margin-right: 1em;
            }
    
    .group-break {
        height: 20px !important;
    }
    .not_moderated {
        opacity: 0.5;
    }
        .amount {
        width: 100%;
        text-align: right;
        font-family: "Roboto";
        font-size: 14px;
        background-color: ${css.backgroundColor};
        border: 1px solid ${css.inputBorderColor};
        transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
        border-radius: 0.4em;
        padding: 5px;
        resize: vertical;
        width: 100%;
        color: inherit;
        /*min-height: 3.3em;*/
        line-height: 18px;
        }
        .amount:focus,
        .amount:hover {
        box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .add-moderator {
        width: 100%;
        font-family: "Roboto";
        font-size: 14px;
        background-color: ${css.backgroundColor};
        border: 1px solid ${css.inputBorderColor};
        transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
        border-radius: 0.4em;
        padding: 5px;
        resize: vertical;
        width: 100%;
        color: inherit;
        line-height: 18px;
        height: 30px;
        }
        .add-moderator:focus,
        .add-moderator:hover {
        box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .moderator-section {
        display: table-row;
        width: 100%;
        height: 40px;
        font-weight: 400;
        }
        .moderator {
        display: table-row;
        width: 100%;
        height: 40px;
        border: 1px solid #eee;
        }

        .submitCell {
        display: table-cell;
        vertical-align: middle;
        }
        .submit {
        font-family: "Roboto";
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;

        color: inherit;
        background-color: transparent;
        border: 1px solid ${css.borderColor};
        padding: 10px;
        transition: 200ms border-color, 200ms background-color, 200ms color;
        z-index: 1;
        border-radius: 0.3em;
        /*background-color: ${css.activeButtonBackgroundColor};*/
        background-color: white;
        }
        .submit:hover {
        box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .paper {
        padding: 20px;
        font-family: "Roboto";
        background-color: ${css.backgroundColor};
        border: 1px solid ${css.borderColor};
        transition: 200ms background-color, 200ms border-color;
        border-radius: 0.3em;
        /*user-select: none;*/
        }



        label:hover {
        cursor: pointer;
        }
        .form {
        padding: 2em;
        justify-content: center;
        align-items: center;
        }
        .form > *:not(:first-child) {
        margin-top: 2em;
        }
        .inline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        }
        label + div {
        margin-top: 1em;
        }
        label > :global(:first-child) {
        margin-right: 1em;
        }
        .dtable {
        display: table;
        width: 100%;
        }
        .drow {
        display: table-row;
        width: 100%;
        height: 28px;
        }
        .dcell {
        display: table-cell;
        vertical-align: middle;
        }
        .badge {
        display: table-cell;
        vertical-align: middle;
        font-size: 18px;
        width: 40px;
        min-width: 30px;
        color: rgb(96,96,96);
        }
        .icon {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        }
        .notice {
        text-align: center;
        margin-top: 10px;
        border: solid 1px grey;
        border-radius: 5px;
        padding: 10px;
        background-color: wheat;
        }
    `}</style>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comments: {
      width: "100%",
      height: "80px",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      borderRadius: "4px",
      padding: "10px",
      marginBottom: "30px",
      fontSize: "12px",
      fontWeight: 400,
    },
    spacer: {
      flexGrow: 1,
    },
    controls: {
      display: "flex",
      marginTop: "10px",
      marginBottom: "10px",
    },
    separator: {
      width: "100%",
      borderBottom: "1px solid #eeeeee",
      marginTop: "5px",
      marginBottom: "10px",
    },
    table: {
      width: "100%",
    },
    cell: {
      margin: "auto",
    },
    scroll: {
      width: "100%",
      fontSize: "12px",
      fontWeight: 400,
    },
    tableHeader: {
      width: "100%",
      display: "flex",
      color: "rgba(0, 0, 0, 0.54)",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    row: {
      width: "100%",
      display: "flex",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "10px",
      paddingRight: "10px",
      borderRadius: "4px",
    },
    section: {
      maxWidth: "800px",
      margin: "auto",
      fontFamily: "Roboto",
    },
    accountTable: {
      paddingBottom: "20px",
      paddingTop: "10px",
      fontWeight: "400",
    },
    account: {
      fontWeight: "400",
      fontSize: "14px",
      wordBreak: "break-all",
    },
    chapter: {
      fontSize: "15px",
      marginBottom: "10px",
      marginTop: "10px",
      fontFamily: "Roboto",
      fontWeight: 400,
      paddingLeft: "20px",
    },
    heading: {
      paddingLeft: "5px",
    },
    leftColumn: {
      paddingRight: "10px",
      paddingBottom: "20px",
    },
    rightColumn: {
      paddingLeft: "10px",
      paddingBottom: "20px",
    },
    span: {
      paddingBottom: "20px",
    },
    btn: {
      width: "100px",
      marginRight: "10px",
      marginBottom: "20px",
    },
    inputField: {
      backgroundColor: "#eeeeee",
      border: "1px solid #444444",
      borderRadius: 3,
      padding: "3px 6px",
      transition:
        "200ms background-color, 200ms border-color, 200ms box-shadow",
      minWidth: 0,
      width: "100%",
    },
    paper: {
      padding: theme.spacing(3, 3, 1),
      marginBottom: "20px",
    },
  })
)

export default Preferences
